export const EXPO_PUBLIC_APP_VERSION = "6.177.0";
export const EXPO_PUBLIC_APP_NAME = "lawnstarter-customer-web";
export const EXPO_PUBLIC_BRAND_SLUG = "lawnstarter";
export const EXPO_PUBLIC_GOOGLE_API_KEY = "AIzaSyCtW4KAA_kr-LPbnH6EMPwOsnjvxqXTVuk";
export const EXPO_PUBLIC_CANCELLATION_SUPPORT_PHONE_NUMBER = "1-855-577-9573";
export const EXPO_PUBLIC_CANCELLATION_ZENDESK_WEB_CHANNEL_KEY = "9ea70407-699c-427c-b6ef-cf6d18950947";
export const EXPO_PUBLIC_ZENDESK_WEB_CHANNEL_KEY = "ed3b5572-9bbc-4e3b-add8-4bf23540f92b";
export const EXPO_PUBLIC_SHARE_ON_NEXTDOOR_TIMEOUT_MINUTES = "5";
export const EXPO_PUBLIC_HELP_CENTER_CUSTOMER_POLICY_FAQ_3_CUT_MIN = "https://lawncarehelpcenter.zendesk.com/hc/en-us/articles/12698339688850--Customer-Customer-Policy-FAQs";
export const EXPO_PUBLIC_HELP_CENTER_CUSTOMER_POLICY_FAQ_WITHIN_48H = "https://lawncarehelpcenter.zendesk.com/hc/en-us/articles/12698339688850--Customer-Customer-Policy-FAQs";
export const SENTRY_ENVIRONMENT = "indigo";
export const SENTRY_PROJECT_SLUG = "https://7a64fe4d24c140af9099be12c5818127@sentry.io/1367049";
export const EXPO_PUBLIC_ENV = "indigo";
export const EXPO_PUBLIC_CUST_WEB_BASE_URL = "https://indigo-my.lawnstarter.com";
export const EXPO_PUBLIC_PARTNERS_BASE_URL = "https://indigo-partners-api.lawnstarter.com";
export const EXPO_PUBLIC_API_GRAPHQL_ENDPOINT = "https://indigo-api.lawnstarter.com/graphql";
export const API_BASE_URL = "https://indigo-api.lawnstarter.com";
export const SIGNUP_BASE_URL = "https://indigo-signup.lawnstarter.com";
export const SEGMENT_KEY = "wyRJQYEsmnjRT5YbuBmgwFWUpZpMbikm";
export const STRIPE_KEY = "pk_test_Gwo1qUvFZUQrufioqsESkESd";
export const LEGACY_CUST_WEB_BASE_URL = "https://indigo-legacy-my.lawnstarter.com";
export const FLAGSMITH_ENVIRONMENT_KEY = "XySXXtqt5UfgpiNtWgfXiC";